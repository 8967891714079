<template>
    <v-app id="inspire">
        <div class="px-4">
            <loading
                :is-show="loadingDialog"
                :is-done="loadingFinished"
                :is-html="isHtml"
                :message="loadingMessage"
                v-on:close-dialog="closeDialog"
            />
            <emv-form
                ref="emvForm"
                :selected-item="selectedEmv"
                :is-show="showFormDialog"
                :branches="branches"
                :deviceTypes="deviceTypes"
                :paymentAgents="paymentAgents"
                :isNotPaymentAgent="isNotPaymentAgent"
                :users="users"
                :merchants="merchants"
                :form-type="emvFormType"
                v-on:close-dialog="closeDialog"
                v-on:save-emv="saveEmv"
            />
            <v-container class="container--fluid grid-list-md text-left" :style="{ fontSize: '15px', color: '#1d6499' }">
                <v-icon :style="{ color: '#1d6499', paddingBottom: '11px', marginRight: '5px' }" left-bottom x-large
                    >mdi-cogs</v-icon
                >
                EMV <span class="text-h5 font-weight-bold"> ({{ totalEmvs }}) </span>
            </v-container>
            <v-card elevation="2" outlined shaped tile class="pa-1">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card-title>
                        <v-container grid-list-md text-xs-center>
                            <v-layout row wrap>
                                <v-flex>
                                    <div class="d-flex">
                                        <span class="mt-4 mr-2">Search By:</span>
                                        <v-text-field
                                            class="mr-3"
                                            label="EMV Parameter ID"
                                            v-model="query.emvParameterIndex"
                                            single-line
                                            outlined
                                            @change="search($event, 'emvParameterIndex')"
                                            data-cy="module-emv-id"
                                        ></v-text-field>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-title>
                </div>
                <div class="d-flex flex-no-wrap justify-space-between mr-lg-10">
                    <!-- <v-card-title> -->
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap class="float-lg-right">
                            <v-flex xs12 sm4 v-if="hasPermission('ROLE_TDM_VIEW_TERMINAL')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown"  style="min-width: 200px;" class="mt-2" color="primary" @click="resetInputs()" data-cy="button-search">
                                    <v-icon dark left> mdi-history </v-icon> Reset
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm4 v-if="hasPermission('ROLE_TDM_VIEW_TERMINAL')">
                                <v-btn
                                    :block="$vuetify.breakpoint.mdAndDown"
                                    style="min-width: 200px"
                                    class="mt-2"
                                    color="primary"
                                    @click="queryData(true)"
                                    data-cy="button-search"
                                >
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm4 v-if="hasPermission('ROLE_TDM_CREATE_TERMINAL')">
                                <v-btn
                                    :block="$vuetify.breakpoint.mdAndDown"
                                    style="min-width: 200px"
                                    class="mt-2"
                                    color="primary"
                                    @click.stop="showEmvForm('CREATED')"
                                    data-cy="button-create"
                                >
                                    <v-icon dark left> mdi-plus</v-icon>Create New Emv
                                </v-btn>
                            </v-flex>
                            <!-- <v-flex xs12 sm6 >
                                 <v-select class="px-4" :items="reportFormats" label="Report Format" @change="getReportFormat"></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 >
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="testDownloadReport()">
                                    <v-icon dark left> mdi-download</v-icon> Download
                                </v-btn>
                            </v-flex> -->
                        </v-layout>
                    </v-container>
                    <!-- </v-card-title> -->
                </div>
            </v-card>

            <v-data-table
                :footer-props="{'items-per-page-options': [10, 20, 50]}"
                :hide-default-header="true"
                :headers="headers"
                :items="displayEmvList"
                :loading="loading"
                :page="page"
                :pageCount="numberOfPages"
                :options.sync="options"
                :server-items-length="totalEmvs"
                class="elevation-2 mt-4 px-4"
            >
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr :colspan="headers.length">
                            <th v-for="header in headers" :key="header.text">
                                <span class="d-flex justify-center">{{ header.text }}</span>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                            <!-- <td> <span class="d-flex justify-center"> {{ item.country.name }} </span></td> -->
                            <td>
                                <span class="d-flex justify-center"> {{ item.emvId }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center"> {{ item.schemeReference }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center"> {{ item.issuerReference }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center"> {{ item.trmDataPresent }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center">
                                    <!-- <v-btn fab dark x-small color="primary" class="mr-2" @click.stop="viewTerminal(item)"> -->
                                    <v-btn
                                        fab
                                        dark
                                        x-small
                                        color="primary"
                                        class="mr-2"
                                        @click.stop="showEmvForm('VIEW', item)"
                                        v-if="hasPermission('ROLE_TDM_VIEW_TERMINAL')"
                                    >
                                        <v-icon dark medium>mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        dark
                                        x-small
                                        color="primary"
                                        @click.stop="showEmvForm('UPDATE', item)"
                                        v-if="hasPermission('ROLE_TDM_UPDATE_TERMINAL')"
                                    >
                                        <v-icon dark medium>mdi-pencil</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning"> Found no results. </v-alert>
                </template>
            </v-data-table>
        </div>
    </v-app>
</template>

<script>
import API from "@/api/API.js";
import Loading from "@/views/components/Loading.vue";
import emvForm from "@/views/components/emvForm.vue";
import { mapGetters } from "vuex";

export default {
    name: "EmvList",
    components: {
        Loading,
        emvForm,
    },
    data() {
        return {
            filterQry: {
                qryName: "",
            },
            headers: [],
            displayEmvList: [],
            remainderEmvList: [],
            // authorities: [],
            merchants: [],
            branches: [],
            deviceTypes: [],
            paymentAgents: [],
            isNotPaymentAgent: false,
            users: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            selectedEmv: {},
            page: 1,
            totalEmvs: 0,
            numberOfPages: 0,
            emvFormType: "CREATED",
            options: {
                rowsPerPage: 10,
            },
            details: {
                title: "",
                item: {},
            },
            query: {
                emvParameterIndex: "",
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
        };
    },
    watch: {
        options: {
            handler() {
                console.log("1");
                this.queryData();
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters(["attributes", "user", "authorities"]),
    },
    async mounted() {
        this.setHeaders();
        //this.queryData()
    },
    methods: {
        resetInputs() {
            this.query.emvParameterIndex = ''
            this.displayEmvList = [];
            this.queryData();
        },
        hasPermission(authority) {
            if (this.authorities.some((x) => x.name === authority)) {
                return true;
            }
            return false;
        },
        async queryData(isSearch = false) {
            this.loading = true;
            if (isSearch) {
                this.options.page = 1;
            }
            const { page, itemsPerPage } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage;
                const listResponse = await API.getEmvs(pageNumber, limit, this.query);
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`);
                } else {
                    //use old code for pagination
                    var filteredList = listResponse.emvs;
                    console.log("filteredList", filteredList);
                    if (page > 1 && this.remainderEmvList.length > 0) {
                        filteredList = this.remainderEmvList.concat(filteredList);
                    }
                    this.totalEmvs = listResponse.totalItems
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayEmvList = filteredList.slice(0, end)
                    this.remainderEmvList = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = listResponse.pageCount
                    // this.totalEmvs = page > 1 ? pageNumber * itemsPerPage + filteredList.length : filteredList.length;
                    // const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage;
                    // console.log("end", end);
                    // this.displayEmvList = filteredList.slice(0, end);
                    // console.log("displayEmvList", this.displayEmvList);
                    // this.remainderEmvList = filteredList.length < itemsPerPage ? [] : filteredList.slice(end);
                    // this.numberOfPages = this.totalEmvs / itemsPerPage;
                    // const hasMoreResponse = await sampleAPI.getListOfEmvs(this.totalEmvs, 1, this.query);
                    // console.log("hasMoreResponse", hasMoreResponse);
                    // if (!hasMoreResponse || hasMoreResponse.error) {
                    //     //error getting data
                    //     console.log(`${hasMoreResponse.error}`);
                    // } else {
                    //     var hasMoreList = hasMoreResponse.data;
                    //     if (hasMoreList.length > 0) {
                    //         this.totalEmvs = this.totalEmvs + 1;
                    //         this.numberOfPages = this.numberOfPages + 1;
                    //     }
                    // }
                }
                this.loading = false;
            } catch (e) {
                console.log(e);
                this.loading = false;
            }
        },
        setHeaders() {
            var headers = [
                {
                    text: "EMV Parameter Index",
                    value: "emvId",
                },
                {
                    text: "Scheme Reference",
                    value: "schemeReference",
                },
                {
                    text: "Issuer Reference",
                    value: "issuerReference",
                },
                {
                    text: "TRM Data Present",
                    value: "trmDataPresent",
                },
                {
                    text: "Actions",
                    value: "controls",
                    sortable: false,
                },
            ];
            this.headers = headers;
        },
        showEmvForm(what, item = {}) {
            this.emvFormType = what;
            this.selectedEmv = item;
            this.showFormDialog = true;
        },
        closeDialog(type) {
            console.log("type:", type);
            if (type === "details") {
                this.details.title = "";
                this.details.item = {};
                this.isShowDetails = false;
            } else if (type === "CREATED" || type === "UPDATE" || type === "VIEW") {
                this.showFormDialog = false;
            } else if (type === "loading") {
                this.loadingDialog = false;
                this.queryData();
            } else if (type === "loading-error") {
                this.loadingDialog = false;
            }
        },
        async saveEmv(value) {
            this.loadingDialog = true;
            this.isHtml = true;
            this.loadingFinished = false;
            if (value) {
                console.log(value);
                if (value.formType === "CREATED") {
                    this.loadingMessage = `Creating new EMV<strong><i>${value.emvParameterIndex}</i></strong>`;
                    setTimeout(async () => {
                        const createResponse = await API.createEmv(value);
                        console.log("CREATE EMV",createResponse);
                        this.loadingFinished = true;
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false;
                            this.loadingMessage = createResponse.error;
                        } else {
                            this.loadingMessage = ` Successfully created EMV <strong><i>${value.emvParameterIndex}</i></strong>`;
                            this.showFormDialog = false;
                            this.$refs.emvForm.resetForm();
                        }
                    }, 2000);
                } else if (value.formType === "UPDATE") {
                    this.loadingMessage = `Updating EMV <strong><i>${value.emvParameterIndex}</i></strong>`;
                    setTimeout(async () => {
                        const updateResponse = await API.updateEmv(value);
                        this.loadingFinished = true;
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false;
                            this.loadingMessage = updateResponse.error;
                        } else {
                            this.loadingMessage = `Successfully updated EMV <strong><i>${value.emvParameterIndex}</i></strong>`;
                            this.showFormDialog = false;
                            this.$refs.emvForm.resetForm();
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command";
                    this.loadingFinished = true;
                }
            } else {
                this.loadingMessage = "Error processing data.";
                this.loadingFinished = true;
            }
        },
        search(event, type) {
            this.query[type] = event;
        },
    },
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%;
}
</style>
